<template>

<app-panel v-on:close="$emit('close')">

	<app-panel-title text="Columns" />

	<app-panel-check v-for="(text, id) in data.columns" :key="id" :active="$_.contains(model.columns, id)" :text="text" v-on:toggle="onColumnToggle(id)" />

	<app-panel-title text="Settings" />

	<app-panel-check :active="model.head" text="Include Column Names" v-on:toggle="model.head = !model.head" />

	<app-panel-content :space="true">

		<app-button text="Generate & download" :loading="is.loading" v-on:click="onGenerateClick" />

	</app-panel-content>

</app-panel>

</template>

<script>

import { validationMixin } from 'vuelidate'

export default {

	mixins: [validationMixin],

	props: ['data'],

	data: function() {

		return {
			is: {
				loading: false
			},
			model: {
				head: true,
				columns: []
			}
		}

	},

	validations: {
		model: {
		}			
	},

	created: function() {

		this.model.columns = this.$_.keys(this.data.columns)

	},

	watch: {

		data: {
			deep: true,
			handler: function() {

				this.model.columns = this.$_.keys(this.data.columns)

			}
		}

	},

	methods: {

		onGenerateClick: function() {

			this.is.loading = true

			var data = this.$util.copy(this.data.params)

			data.export = this.model
			data.index = this.data.index

			this.$api.post(this.data.endpoint, data).then(function(json) {

				this.is.loading = false

				window.location.href = json.file

				this.$emit('close')

			}.bind(this))

		},

		onColumnToggle: function(id) {

			this.$util.array.toggle(this.model.columns, id)

		}

	}

}

</script>

<style scoped>

</style>
